import React from 'react'
import { NewsItemProps } from '../utils/types'

function NewsItem({ newsItem }: NewsItemProps) {
  const { avatar, comments_count, content } = newsItem

  return (
    <li className="flex space-x-3 py-4">
      <div className="flex-shrink-0">
        <img className="h-8 w-8 rounded-full" src={avatar} alt="Antoine Murard" />
      </div>
      <div className="min-w-0 flex-1">
        <p className="text-sm text-gray-800">{content}</p>
        <div className="mt-2 flex">
          <span className="inline-flex items-center text-sm">
            <button
              type="button"
              className="inline-flex space-x-2 text-gray-400 hover:text-gray-500"
            >
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 2c-2.236 0-4.43.18-6.57.524C1.993 2.755 1 4.014 1 5.426v5.148c0 1.413.993 2.67 2.43 2.902.848.137 1.705.248 2.57.331v3.443a.75.75 0 001.28.53l3.58-3.579a.78.78 0 01.527-.224 41.202 41.202 0 005.183-.5c1.437-.232 2.43-1.49 2.43-2.903V5.426c0-1.413-.993-2.67-2.43-2.902A41.289 41.289 0 0010 2zm0 7a1 1 0 100-2 1 1 0 000 2zM8 8a1 1 0 11-2 0 1 1 0 012 0zm5 1a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="font-medium text-gray-900">{comments_count}</span>
            </button>
          </span>
        </div>
      </div>
    </li>
  )
}

export default NewsItem
